import React from 'react'
import { Link } from 'gatsby'
import { Container, Hero, Layout } from '../components'
import { Text } from 'rebass'

const NotFoundPage = () => (
  <Layout>
    <Hero
      title="Valitettavasti etsimääsi sivua ei löytynyt."
    />

    <Container py={[4,5]} maxWidth='64em'>
      <Text fontSize={[2,3,null,4]} textAlign='center'>
        <Link to='/'>Takaisin etusivulle &rarr;</Link>
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
